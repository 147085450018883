import React, { Fragment } from 'react';

export default function CardHighlights({ highlights, facility, size = null }) {
  const checkedHighlights = highlights.filter((highlight) => highlight.check);
  return (
    <>
      <div className={`flex flex-col justify-between self-center items-start`}>
        {checkedHighlights.map((highlight, index) => (
          <Fragment key={highlight.type + index}>
            {highlight.type === 'facility_eletric_car' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    EV Charger{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_additional_comments' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Note{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )}
            {/* {highlight.type === 'facility_extra_free_cancellation' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Extra Fee Cancellation{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )} */}
            {highlight.type === 'facility_open_hour' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Facility Access{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_distance' && (
              <div className="flex flex-row justify-start items-start">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Distance{' '}
                    <span className="font-bold">
                      {highlight.description
                        ? highlight.description
                        : `${facility.facility_airport_distance} ${
                            facility.facility_airport_distance === 1
                              ? 'mile'
                              : 'miles'
                          }`}
                    </span>
                  </span>
                </p>
              </div>
            )}
            {highlight.type === 'facility_accessibility' && (
              <div className="flex flex-row justify-start items-start">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Wheelchair Accessible
                    {highlight.description && (
                      <span className="font-bold">
                        {highlight.description && highlight.description}
                      </span>
                    )}
                  </span>
                </p>
              </div>
            )}

            {highlight.type === 'facility_free_shuttles' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    Free Shuttle{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )}

            {highlight.type === 'facility_no_shuttles' && (
              <div className="flex flex-row justify-start items-start ">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-normal">
                    No Shuttle{' '}
                    <span className="font-bold">
                      {highlight.description && highlight.description}
                    </span>
                  </span>
                </p>
              </div>
            )}

            {highlight.type === 'facility_free_cancellation' && (
              <div className="flex flex-row justify-start items-start mt-4">
                <p
                  className={`text-xs text-left flex flex-col gap-1 justify-center items-start`}
                >
                  <span className="font-bold text-[#069B27]">
                    Free Cancellations{' '}
                    {highlight.description && highlight.description}
                  </span>
                </p>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
}
