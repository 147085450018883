const check_highlights = [
  'facility_free_shuttles',
  'facility_no_shuttles',
  'facility_shuttle_frequency',
  'facility_distance',
  'facility_free_cancellation',
  'facility_accessibility',
  'facility_eletric_car',
  'facility_additional_comments',
];
export const isShowHighlights = (highlights) => {
  return (
    highlights?.length > 0 &&
    highlights.find(
      (highlight) =>
        check_highlights.indexOf(highlight.type) !== -1 &&
        highlight.check !== false
    )
  );
};
